import {
  Grid2 as Grid,
  TextField,
  Typography,
  Button,
  Card,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { apiStem } from "../utils/variables";
import axios from "axios";
const NewProjectImages = ({
  setAlertMessage,
  user,
  projectId,
  setActiveStep,
}) => {
  const [uploadStates, setUploadStates] = useState({
    frontCover: null,
    backCover: null,
  }); //upload states are null 'uploading' 'complete' 'error'
  const onSubmit = async (data) => {
    data.preventDefault();
    try {
      console.log("images uploaded");

      setActiveStep(2);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(uploadStates);
  const imageUploadHandler = async (e) => {
    try {
      const validFileTypes = ["image/png", "image/webp", "image/jpeg"];
      const file = e.target.files[0];
      const name = e.target.name;
      console.log(e.target.name, "handler ran", e.target.files[0]);
      if (validFileTypes.includes(file.type)) {
        console.log("uploading");
        setUploadStates({ ...uploadStates, [name]: "uploading" });
        const formData = new FormData();
        formData.append("imageFile", file);
        formData.append("ownerId", user.sub);
        formData.append("projectId", projectId);
        formData.append("type", name);

        const result = await axios.post(`${apiStem}/images`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        result.status === 200
          ? setUploadStates({ ...uploadStates, [name]: "complete" })
          : setUploadStates({ ...uploadStates, [name]: "error" });
      } else {
        setAlertMessage("invalid file format. Choose from PNG, JPEG, or WEBP");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={(e) => onSubmit(e)} className="signup-form">
        <Typography>
          Please select images for your front and back cover. These should be in
          webp, png, or jpg format with minimum dimensions 1024 x 768 (or
          whatever) pixels
        </Typography>
        <Grid container direction={"column"} spacing={1}>
          <Grid item>
            <Typography>Front Cover</Typography>
            {uploadStates.frontCover === "uploading" ? (
              <CircularProgress />
            ) : uploadStates.frontCover === "error" ? (
              <p>some error message</p>
            ) : uploadStates.frontCover === "complete" ? (
              <p>show the thumbnail</p>
            ) : (
              <TextField
                type="file"
                name="frontCover"
                onChange={(e) => imageUploadHandler(e)}
              />
            )}
          </Grid>
          <Grid item>
            <Typography>Back Cover</Typography>
            {uploadStates.backCover === "uploading" ? (
              <CircularProgress />
            ) : uploadStates.backCover === "error" ? (
              <p>some error message</p>
            ) : uploadStates.backCover === "complete" ? (
              <p>show the thumbnail</p>
            ) : (
              <TextField
                type="file"
                name="backCover"
                onChange={(e) => imageUploadHandler(e)}
              />
            )}
          </Grid>
        </Grid>
        <Button type="submit" variant="outlined" sx={{ margin: "20px" }}>
          Next
        </Button>
      </form>
    </>
  );
};

export default NewProjectImages;
