import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h2>Our Ethos</h2>
      <h4>How It Works</h4>
      <p>
        We will outline our terms here
      </p>
    </div>
  );
};

export default About;
