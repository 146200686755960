import { Link } from "react-router-dom";

const MyProjects = () => {
  return (
    <>
      <h2>My Projects</h2>
      <Link to="/createNewProject">Create New Project</Link>
    </>
  );
};

export default MyProjects;
