import NewProjectText from "../components/NewProjectText";
import NewProjectAudio from "../components/NewProjectAudio";
import NewProjectImages from "../components/NewProjectImages";
import NewProjectConfirm from "../components/NewProjectConfirm";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { Button, Card, Box } from "@mui/material";
import { useState, useEffect } from "react";

const createNewProject = ({ setAlertMessage, user }) => {
  const active = JSON.parse(localStorage.getItem("activeProject"));
  console.log(active);
  const [projectId, setProjectId] = useState("");
  const steps = ["Information", "Images", "Audio", "Complete"];
  const [activeStep, setActiveStep] = useState(active?.activeStep || 0);
  useEffect(() => {
    if (active) {
      setProjectId(active.active);
      setActiveStep(active.activeStep);
    } else {
      const newId = `${new Date().getTime()}_${user.sub}`;
      setProjectId(newId);
      localStorage.setItem(
        "activeProject",
        JSON.stringify({ active: newId, activeStep: 0 })
      );
    }
  }, []);

  return (
    <>
      {" "}
      <h2>Create New Project</h2>
      <Card raised={true} sx={{ borderRadius: "10px" }}>
        {activeStep === 0 && (
          <NewProjectText
            setActiveStep={setActiveStep}
            setAlertMessage={setAlertMessage}
            projectId={projectId}
            user={user}
          />
        )}
        {activeStep === 1 && (
          <NewProjectImages
            setActiveStep={setActiveStep}
            setAlertMessage={setAlertMessage}
            projectId={projectId}
            user={user}
          />
        )}
        {activeStep === 2 && (
          <NewProjectAudio
            setActiveStep={setActiveStep}
            setAlertMessage={setAlertMessage}
            projectId={projectId}
            user={user}
          />
        )}
        {activeStep === 3 && (
          <NewProjectConfirm
            setActiveStep={setActiveStep}
            setAlertMessage={setAlertMessage}
            projectId={projectId}
            user={user}
          />
        )}
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: "30px" }}
        ></Box>
      </Card>
      <Stepper activeStep={activeStep} sx={{ margin: "30px" }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default createNewProject;
